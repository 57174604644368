import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Form, Button, Card, Space, Tabs, Row, Col, Checkbox, InputNumber,
} from 'antd';
import {
  MinusOutlined, PlusOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

const InputMoney = ({
  onButtonClick, record, onMoneyInput, moneyValue,
}) => (
  <Row>
    <Col span={24}>
      <Button.Group>
        <Button onClick={() => onButtonClick(false, record)} size="small" icon={<MinusOutlined />} disabled={Number(moneyValue) < 200} />
        <InputNumber
          type="text"
          value={moneyValue}
          style={{
            width: 80,
            borderRadius: '0',
          }}
          size="small"
          status={record.errInfo && 'error'}
          onChange={(e) => onMoneyInput(e, record)}
          controls={false}
        />
        <Button onClick={() => onButtonClick(true, record)} size="small" icon={<PlusOutlined />} disabled={Number(moneyValue) + 100 > Number(record?.maxAmt || record?.applyAmt)} />
      </Button.Group>
    </Col>
    {record.errInfo && <Col span={24} style={{ textAlign: 'center', color: 'red' }}>{record.errInfo}</Col>}
  </Row>
);

InputMoney.propTypes = {
  onButtonClick: PropTypes.func,
  onMoneyInput: PropTypes.func,
  record: PropTypes.objectOf,
  moneyValue: PropTypes.string,
};
InputMoney.defaultProps = {
  onButtonClick: () => {},
  onMoneyInput: () => {},
  record: {},
  moneyValue: '',
};
export default InputMoney;
