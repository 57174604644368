import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';
import {
  IconMember,
} from '@/assets/svg';

const Record = lazy(() => import('./record'));
const Examine = lazy(() => import('./examine'));
const CheckPendingReview = lazy(() => import('./checkPending'));

export default {
  path: 'settlement',
  element: <Outlet />,
  options: {
    title: '结算管理',
    permissionKey: 'menu-settlement',
    key: 'menu-settlement',
    icon: <Icon component={IconMember} />,
    link: '/settlement',
  },
  children: [
    {
      path: 'examine',
      element: <Outlet />,
      options: {
        title: '结算审核',
        permissionKey: 'menu-settlement_audit',
        key: 'menu-settlement_audit',
        icon: null,
        link: '/settlement/examine',
      },
      children: [
        {
          path: '',
          element: <ProtectedRoute permissionKey="menu-settlement_audit" title="结算审核" component={<Suspense><Examine /></Suspense>} />,
        },
        {
          path: 'checkPending/:id',
          element: <ProtectedRoute permissionKey="menu-settlement_audit" title="查看明细" component={<Suspense><CheckPendingReview /></Suspense>} />,
        },
      ],
    },
    {
      path: 'record',
      element: <Outlet />,
      options: {
        title: '通过记录',
        permissionKey: 'menu-settlement_approve',
        key: 'menu-settlement_approve',
        icon: null,
        link: '/settlement/record',
      },
      children: [
        {
          path: '',
          element: <ProtectedRoute permissionKey="menu-settlement_approve" title="通过记录" component={<Suspense><Record /></Suspense>} />,
        },
        {
          path: 'checkDetailRecord/:id',
          element: <ProtectedRoute permissionKey="menu-settlement_approve" title="查看明细" component={<Suspense><CheckPendingReview /></Suspense>} />,
        },
      ],
    },
  ],
};
