export default {
  payChannel: [
    {
      label: '公众号',
      name: 'OFFICIAL',
      value: 'OFFICIAL',
    },
  ],
  dealChannel: [
    {
      label: '微信财付通账户',
      value: 'WECHAT',
    },
    // {
    //   label: '支付宝账户',
    //   value: 'ALIPAY',
    // },
  ],
  deductedType: [
    {
      label: '代发薪资',
      value: 'PAY_WAGE',
    },
    {
      label: '预支薪资',
      value: 'PAY_ADVANCE',
    },
  ],
  tradeChannelAccountType: [
    {
      label: '基本账户',
      value: 'BASIC',
    },
    {
      label: '手续费账户',
      value: 'FEES',
    },
  ],
  successGrantStatus: [
    {
      label: '未提现',
      value: 'NOT_WITHDRAW',
    },
    {
      label: '已扣回',
      value: 'DEDUCTED',
    },
    {
      label: '提现中',
      value: 'WITHDRAWING',
    },
    {
      label: '已提现',
      value: 'WITHDRAWN',
    },
  ],
  detailedGrantStatus: [
    {
      label: '待发放',
      value: 'PENDING',
    },
    {
      label: '发放失败',
      value: 'FAIL',
    },
    {
      label: '未提现',
      value: 'NOT_WITHDRAW',
    },
    {
      label: '已扣回',
      value: 'DEDUCTED',
    },
    {
      label: '提现中',
      value: 'WITHDRAWING',
    },
    {
      label: '已提现',
      value: 'WITHDRAWN',
    },
  ],
  withdrawStatus: [
    {
      label: '已发放',
      value: 'SUCCESS',
    },
    {
      label: '已扣回',
      value: 'DEDUCTED',
    },
    {
      label: '提现中',
      value: 'WITHDRAWING',
    },
    {
      label: '已提现',
      value: 'WITHDRAWN',
    },
  ],
  detailsStatus: [
    {
      label: '待发放',
      value: 'PENDING',
    },
    {
      label: '发放成功',
      value: 'SUCCESS',
    },
    {
      label: '发放失败',
      value: 'FAIL',
    },
  ],
  batchStatus: [
    {
      label: '待审核',
      value: 'PENDING',
    },
    {
      label: '发放中',
      value: 'RUNNING',
    },
    {
      label: '已发放',
      value: 'SUCCESS',
    },
    {
      label: '已拒绝',
      value: 'REFUSE',
    },
  ],
  accountWithdraw: [
    {
      label: '提现中',
      value: 'RUNNING',
    },
    {
      label: '提现失败',
      value: 'FAIL',
    },
    {
      label: '提现成功',
      value: 'SUCCESS',
    },
  ],
  selectionData: [
    {
      label: '是',
      value: true,
    },
    {
      label: '否',
      value: false,
    },
  ],
  staffStatusEnum: [
    {
      label: '是',
      value: true,
    },
    {
      label: '否',
      value: false,
    },
  ],
  advanceStatusEnum: [
    {
      label: '是',
      value: true,
    },
    {
      label: '否',
      value: false,
    },
  ],
  detailedPageStatusEnum: {
    PENDING: 'default',
    FAIL: 'error',
    NOT_WITHDRAW: 'warning',
    DEDUCTED: 'error',
    WITHDRAWING: 'processing',
    WITHDRAWN: 'success',
  },
  approvalStatus: [
    {
      label: '驻厂待审核',
      value: 'RESIDENT_PERSONNEL_PENDING',
    },
    {
      label: '驻厂已拒绝',
      value: 'RESIDENT_PERSONNEL_REFUSE',
    },
    {
      label: '驻厂主管待审核',
      value: 'RESIDENT_MANAGER_PENDING',
    },
    {
      label: '财务会计待审核',
      value: 'FINANCIAL_ACCOUNTANT_PENDING',
    },
    {
      label: '财务会计已拒绝',
      value: 'FINANCIAL_ACCOUNTANT_REFUSE',
    },
    {
      label: '财务出纳待审核',
      value: 'FINANCIAL_TREASURER_PENDING',
    },
    {
      label: '财务出纳已通过',
      value: 'FINANCIAL_TREASURER_AGREE',
    },
  ],
  applyRecordStatus: [
    {
      label: '未提现',
      value: 'NOT_WITHDRAW',
    },
    {
      label: '已扣回',
      value: 'DEDUCTED',
    },
    {
      label: '已提现',
      value: 'WITHDRAW',
    },
  ],
};
