import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const Voucher = lazy(() => import('./voucher'));
const Record = lazy(() => import('./record'));
const Examine = lazy(() => import('./examine'));
const CheckPendingReview = lazy(() => import('./checkPending'));
const CheckSuccessRecordReview = lazy(() => import('./checkSuccessRecord'));
const CheckRefuseRecordReview = lazy(() => import('./checkRefuseRecord'));
const CheckPendingRecordReview = lazy(() => import('./checkPendingRecord'));

export default {
  path: 'payroll',
  element: <Outlet />,
  options: {
    title: '直接发薪',
    permissionKey: 'menu-payroll_direct',
    key: 'menu-payroll_direct',
    icon: null,
    link: '/weChat/payroll',
  },
  children: [
    {
      path: 'voucher',
      element: <ProtectedRoute permissionKey="menu-payroll_direct_voucher" title="发薪制单" component={<Suspense><Voucher /></Suspense>} />,
      options: {
        title: '发薪制单',
        permissionKey: 'menu-payroll_direct_voucher',
        key: 'menu-payroll_direct_voucher',
        icon: null,
        link: '/weChat/payroll/voucher',
      },
    },
    {
      path: 'examine',
      element: <Outlet />,
      options: {
        title: '发薪审核',
        permissionKey: 'menu-payroll_direct_examine',
        key: 'menu-payroll_direct_examine',
        icon: null,
        link: '/weChat/payroll/examine',
      },
      children: [
        {
          path: '',
          element: <ProtectedRoute permissionKey="menu-payroll_direct_examine" title="发薪审核" component={<Suspense><Examine /></Suspense>} />,
        },
        {
          path: 'checkPending/:id',
          element: <ProtectedRoute permissionKey="menu-payroll_direct_examine" title="查看明细" component={<Suspense><CheckPendingReview /></Suspense>} />,
        },
      ],
    },
    {
      path: 'record',
      element: <Outlet />,
      options: {
        title: '发薪记录',
        permissionKey: 'menu-payroll_direct_record',
        key: 'menu-payroll_direct_record',
        icon: null,
        link: '/weChat/payroll/record',
      },
      children: [
        {
          path: '',
          element: <ProtectedRoute permissionKey="menu-payroll_direct_record" title="发薪记录" component={<Suspense><Record /></Suspense>} />,
        },
        {
          path: 'checkSuccessRecord/:id',
          element: <ProtectedRoute permissionKey="menu-payroll_direct_record" title="查看明细" component={<Suspense><CheckSuccessRecordReview /></Suspense>} />,
        },
        {
          path: 'checkRefuseRecord/:id',
          element: <ProtectedRoute permissionKey="menu-payroll_direct_record" title="查看明细" component={<Suspense><CheckRefuseRecordReview /></Suspense>} />,
        },
        {
          path: 'checkPendingRecord/:id',
          element: <ProtectedRoute permissionKey="menu-payroll_direct_record" title="查看明细" component={<Suspense><CheckPendingRecordReview /></Suspense>} />,
        },
      ],
    },
  ],
};
