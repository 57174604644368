import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';
import {
  IconAccount,
} from '@/assets/svg';

const Account = lazy(() => import('./review'));

export default {
  path: 'account',
  element: <Outlet />,
  options: {
    title: '账户总览',
    permissionKey: 'menu-account',
    key: 'menu-account',
    icon: <Icon component={IconAccount} />,
    link: '/account',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute permissionKey="menu-account" title="账户总览" component={<Suspense><Account /></Suspense>} />,
    },
  ],
};
