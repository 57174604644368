import { makeAutoObservable, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { urlHandler, httpRequest } from '@/utils';
import { storageKeys } from '@/constants';

const LoggedIn = !!Cookies.get(storageKeys.token);
// const LoggedIn = !!'eyJhbGciOiJIUzI1NiJ9.eyJyYW5kb20iOiI1ZDM1NTUyOC01MjRjLTQ2ZjYtYTMzMC1iNDZmYTVmMjJlMTQiLCJzZXJ2aWNlIjoic2hhbmtzLW1hbmFnZXItYmFja2VuZCx0ZXp6b2xvLWZpbmFuY2UtbWFuYWdlciIsInRlbmFudElkIjoiNjI1NzkwN2E1ZTU1MDAwMGE0MDAzYWY2IiwibW9iaWxlIjoiMTk5MjY0MDM0MzEiLCJ0ZXJtaW5hbCI6InBjIiwidXNlcklkIjoiNjI1ZjZmOTE5MmRmODIwZWQxY2JiNjY5In0.ZAaC5_dDfHPNxnCsskJIIw1A5wxN3ubEb3jKGDg0N9k';

const initPermissions = localStorage.getItem(storageKeys.permissions) ? JSON.parse(localStorage.getItem(storageKeys.permissions)) : [];

class Auth {
  profile = {
    username: Cookies.get(storageKeys.username),
    tenantName: '',
    avatar: '',
    tenantId: '',
  }

  logoUrl = '';

  brandUrl = '';

  isLoggedIn = LoggedIn

  permissions = initPermissions;

  isAdmin = false;

  isTreasurer = false;

  constructor() {
    makeAutoObservable(this);
    if (LoggedIn) {
      this.getPermission();
      this.getCurInfo();
    }
  }

  setProfile(username, avatar, tenantName, tenantId) {
    this.profile.username = username;
    this.profile.avatar = avatar;
    this.profile.tenantName = tenantName;
    this.profile.tenantId = tenantId;
  }

  setIsLoggedIn(isLoggedIn) {
    this.isLoggedIn = isLoggedIn;
    if (isLoggedIn) {
      this.getPermission();
      this.getCurInfo();
    }
  }

  async getCurInfo() {
    try {
      const res = await httpRequest.get(`${process.env.REACT_APP_LOCAL_LOGIN_API_ENDPOINT}/user/curInfo`);
      if (res.code === 0) {
        runInAction(() => {
          this.brandUrl = res.data.brandUrl;
          this.logoUrl = res.data.logoUrl;
        });
      }
    } catch (err) {
      console.log(err?.message);
    }
  }

  async getPermission() {
    try {
      const res = await httpRequest.get('/user/current');
      if (res.code === 0) {
        Cookies.set('userName', res?.data?.userName);
        Cookies.set('userName', res?.data?.userName, { domain: urlHandler.getDomain(process.env.REACT_APP_PORTAL_ADDRESS) });

        Cookies.set('tenantId', res?.data?.tenantId);
        Cookies.set('tenantId', res?.data?.tenantId, { domain: urlHandler.getDomain(process.env.REACT_APP_PORTAL_ADDRESS) });

        Cookies.set('tenantName', res?.data?.tenantName);
        Cookies.set('tenantName', res?.data?.tenantName, { domain: urlHandler.getDomain(process.env.REACT_APP_PORTAL_ADDRESS) });
        runInAction(() => {
          const { permissions, admin, financialTreasurer } = res.data || {};
          this.permissions = permissions;
          localStorage.setItem(storageKeys.permissions, JSON.stringify(permissions));
          this.isAdmin = admin;
          this.isTreasurer = financialTreasurer;
          this.setProfile(res?.data?.userName, '', res?.data?.tenantName, res?.data?.tenantId);
        });
      }
    } catch (err) {
      console.log(err?.message || '获取权限失败');
    }
  }

  setPermissions(permissions) {
    this.permissions = permissions;
  }

  clearlogInfo() {
    this.profile = {
      username: '',
      avatar: '',
      tenantName: '',
      tenantId: '',
    };
    this.isLoggedIn = false;
    this.permissions = [];
    this.isAdmin = false;
    this.isTreasurer = false;
  }
}

export default new Auth();
