import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const SmsCountDown = ({
  onSent,
  disabled,
  autoSend,
}) => {
  const [countNumber, setCountNumber] = useState(120);
  const [counting, setCounting] = useState(false);
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    clearInterval(timer.current);
    return () => clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (countNumber < 0 || countNumber > 120) {
      clearInterval(timer.current);
      setCountNumber(120);
      setCounting(false);
    }
  }, [countNumber]);

  const handlePress = async () => {
    if (disabled || counting) {
      return;
    }
    setLoading(true);
    const success = await onSent();
    setLoading(false);
    if (!success) {
      return;
    }
    timer.current = setInterval(() => setCountNumber((pre) => pre - 1), 1000);
    setCounting(true);
    setSent(true);
  };

  const getCodeText = sent ? '重新获取' : '获取验证码';
  return (
    <Button ghost type="primary" loading={loading} disabled={disabled} onClick={handlePress}>
      {counting ? `${countNumber}S后重新获取` : getCodeText}
    </Button>
  );
};
SmsCountDown.propTypes = {
  onSent: PropTypes.func,
  disabled: PropTypes.bool,
  autoSend: PropTypes.bool,
};

SmsCountDown.defaultProps = {
  onSent: () => {},
  disabled: false,
  autoSend: false,
};
export default SmsCountDown;
