import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import sealImg from './image/seal.png';

const PdfPage = ({
  children, batchNo, footer, footerElement,
}) => (
  <div className={styles.page}>
    <div className={styles.header}>
      <img src="https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/assist/e4ec906675c1f1b0e3d89132c7cc7f7.png" alt="logo" className={styles.logo} />
      <span>
        批次号
        {` ${batchNo}`}
      </span>
      <span>电子回单</span>
    </div>
    <img src={sealImg} alt="印章" width="100" className={styles.img} />
    <div className={styles.content}>
      {
        children
      }
    </div>
    {
      footer && (
        <div className={styles.footer}>
          {
            footerElement || (
            <div className={styles.tips}>
              <span>温馨提示：</span>
              本电子回单可以重复查询与下载打印，但每笔经电子签名的电子回单pdf文件只能做一笔记账，不可重复记账
            </div>
            )
          }
        </div>
      )
    }

  </div>
);

PdfPage.propTypes = {
  children: PropTypes.element,
  batchNo: PropTypes.string,
  footer: PropTypes.bool,
  footerElement: PropTypes.element,
};
PdfPage.defaultProps = {
  children: '',
  batchNo: '',
  footer: true,
  footerElement: '',
};

export default PdfPage;
