import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Form, Button, Card, Space, Tabs, Row, Col, Checkbox, InputNumber,
} from 'antd';
import PropTypes from 'prop-types';
import { globalStyles } from '@styles';
import style from './index.module.scss';

const { Content } = globalStyles;
const Counter = ({
  values, step, handleNumberChange, min, max, minIndex, maxIndex,
}) => {
  const [minNumber, setMin] = useState(0);
  const [maxNumber, setMax] = useState(maxIndex);
  const [array, setArray] = useState([]);
  const handleMinReduceClick = () => {
    setMin((val) => {
      const index = val <= 0 ? 0 : val - 1;
      handleNumberChange(array[index], array[maxNumber]);
      return index;
    });
  };
  const handleMinPlusClick = () => {
    setMin((val) => {
      const index = val >= maxNumber ? val : val + 1;
      handleNumberChange(array[index], array[maxNumber]);
      return index;
    });
  };
  const handleMaxReduceClick = () => {
    setMax((val) => {
      const index = val <= minNumber ? val : val - 1;
      handleNumberChange(array[minNumber], array[index]);
      return index;
    });
  };
  const handleMaxPlusClick = () => {
    setMax((val) => {
      const index = val >= array.length - 1 ? array.length - 1 : val + 1;
      handleNumberChange(array[minNumber], array[index]);
      return index;
    });
  };
  useEffect(() => {
    setMax(array.length - 1);
    if (maxIndex) {
      setMax(maxIndex);
    }
    if (minIndex) {
      setMin(minIndex);
    }
  }, [array, maxIndex, minIndex]);
  useEffect(() => {
    const index = typeof (values) === 'number' ? setArray((val) => {
      for (let i = min; i <= (max || values); i += step) {
        val.push(i);
      }
      return [...new Set(val)];
    }) : setArray(() => values?.filter((item) => {
      if (max) {
        return item >= min && item <= max;
      }
      return item >= min;
    }));
  }, (values));
  return (
    <Row style={{ lineHeight: '32px' }}>
      <Button type="primary" ghost onClick={handleMinReduceClick} style={{ borderRadius: '0' }}>-</Button>
      <InputNumber
        disabled
        value={array[minNumber]}
        style={{
          borderRadius: '0', borderBottom: '1px solid #1677ff', borderTop: '1px solid #1677ff',
        }}
      />
      <Button type="primary" ghost onClick={handleMinPlusClick} style={{ borderRadius: '0' }}>+</Button>
      <span style={{ margin: '0 5px' }}>至</span>
      <Button type="primary" ghost onClick={handleMaxReduceClick} style={{ borderRadius: '0' }}>-</Button>
      <InputNumber disabled value={array[maxNumber]} style={{ borderRadius: '0', borderBottom: '1px solid #1677ff', borderTop: '1px solid #1677ff' }} />
      <Button type="primary" ghost onClick={handleMaxPlusClick} style={{ borderRadius: '0' }}>+</Button>
    </Row>
  );
};

Counter.propTypes = {
  values: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf]),
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  minIndex: PropTypes.number,
  maxIndex: PropTypes.number,
  handleNumberChange: PropTypes.func,
};
Counter.defaultProps = {
  values: [0],
  handleNumberChange: () => {},
  step: 1,
  min: 0,
  max: 0,
  minIndex: 0,
  maxIndex: 0,
};
export default Counter;
