import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';
import {
  IconMember,
} from '@/assets/svg';

const Resources = lazy(() => import('./review'));
const Employee = lazy(() => import('./employee/information'));

export default {
  path: 'member',
  element: <Outlet />,
  options: {
    title: '会员',
    permissionKey: 'menu-member',
    key: 'menu-member',
    icon: <Icon component={IconMember} />,
    link: '/member',
  },
  children: [
    {
      path: 'resources',
      element: <ProtectedRoute permissionKey="menu-member_info" title="资源库" component={<Suspense><Resources /></Suspense>} />,
      options: {
        title: '资源库',
        permissionKey: 'menu-member_info',
        key: 'menu-member_info',
        icon: null,
        link: '/member/resources',
      },
    },
    {
      path: 'employee',
      element: <ProtectedRoute permissionKey="menu-member_config" title="信息配置" component={<Suspense><Employee /></Suspense>} />,
      options: {
        title: '信息配置',
        permissionKey: 'menu-member_config',
        key: 'menu-member_config',
        icon: null,
        link: '/member/employee',
      },
    },
  ],
};
