import React from 'react';
import Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';
import Dashboard from './review';
import {
  IconHome,
} from '@/assets/svg';

export default {
  index: true,
  element: <ProtectedRoute allowAccess title="首页" component={<Dashboard />} />,
  options: {
    title: '首页',
    key: 'dashboard',
    icon: <Icon component={IconHome} />,
    link: '/',
    allowAccess: true,
  },
};
