import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const Examine = lazy(() => import('./examine'));
const Manager = lazy(() => import('./manager'));
const Accounting = lazy(() => import('./accounting'));
const PayExamine = lazy(() => import('./payExamine'));
const Detailed = lazy(() => import('./detailed'));

export default {
  path: 'advanceProcess',
  element: <Outlet />,
  options: {
    title: '预支审核',
    permissionKey: 'menu-advance_audit',
    key: 'menu-advance_audit',
    icon: null,
    link: '/advance/advanceProcess',
  },
  children: [
    {
      path: 'examine',
      element: <ProtectedRoute permissionKey="menu-advance_audit_resident" title="驻厂审核" component={<Suspense><Examine /></Suspense>} />,
      options: {
        title: '驻厂审核',
        key: 'menu-advance_audit_resident',
        permissionKey: 'menu-advance_audit_resident',
        icon: null,
        link: '/advance/advanceProcess/examine',
      },
    },
    {
      path: 'manager',
      element: <ProtectedRoute permissionKey="menu-advance_audit_residentManager" title="主管审核" component={<Suspense><Manager /></Suspense>} />,
      options: {
        title: '主管审核',
        key: 'menu-advance_audit_residentManager',
        permissionKey: 'menu-advance_audit_residentManager',
        icon: null,
        link: '/advance/advanceProcess/manager',
      },
    },
    {
      path: 'accounting',
      element: <ProtectedRoute permissionKey="menu-advance_audit_accountant" title="会计审核" component={<Suspense><Accounting /></Suspense>} />,
      options: {
        title: '会计审核',
        key: 'menu-advance_audit_accountant',
        permissionKey: 'menu-advance_audit_accountant',
        icon: null,
        link: '/advance/advanceProcess/accounting',
      },
    },
    {
      path: 'moneyExamine',
      element: <Outlet />,
      options: {
        title: '出纳审核',
        key: 'menu-advance_audit_treasurer',
        permissionKey: 'menu-advance_audit_treasurer',
        icon: null,
        link: '/advance/advanceProcess/moneyExamine',
      },
      children: [
        {
          path: '',
          element: <ProtectedRoute permissionKey="menu-advance_audit_treasurer" title="出纳审核" component={<Suspense><PayExamine /></Suspense>} />,
        },
        {
          path: 'detailed/:id',
          element: <ProtectedRoute permissionKey="menu-payroll_direct_record" title="查看明细" component={<Suspense><Detailed /></Suspense>} />,
        },
      ],
    },
  ],
};
