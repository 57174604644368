import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const Elimination = lazy(() => import('./elimination'));
const WriteOffRecord = lazy(() => import('./writeOffRecord'));
const Comprehensive = lazy(() => import('./comprehensive'));

export default {
  path: 'advanceWriteOff',
  element: <Outlet />,
  options: {
    title: '预支冲销',
    permissionKey: 'menu-advance_writeOff',
    key: 'menu-advance_writeOff',
    icon: null,
    link: '/advance/advanceWriteOff',
  },
  children: [
    {
      path: 'comprehensive',
      element: <ProtectedRoute permissionKey="menu-advance_writeOff_person" title="单笔冲销" component={<Suspense><Comprehensive /></Suspense>} />,
      options: {
        title: '综合冲销',
        permissionKey: 'menu-advance_writeOff_person',
        key: 'menu-advance_writeOff_person',
        icon: null,
        link: '/advance/advanceWriteOff/comprehensive',
      },
    },
    {
      path: 'manage',
      element: <ProtectedRoute permissionKey="menu-advance_writeOff_single" title="单笔冲销" component={<Suspense><Elimination /></Suspense>} />,
      options: {
        title: '单笔冲销',
        permissionKey: 'menu-advance_writeOff_single',
        key: 'menu-advance_writeOff_single',
        icon: null,
        link: '/advance/advanceWriteOff/manage',
      },
    },
    {
      path: 'writeOffRecord',
      element: <ProtectedRoute permissionKey="menu-advance_writeOff_record" title="冲销记录" component={<Suspense><WriteOffRecord /></Suspense>} />,
      options: {
        title: '冲销记录',
        permissionKey: 'menu-advance_writeOff_record',
        key: 'menu-advance_writeOff_record',
        icon: null,
        link: '/advance/advanceWriteOff/writeOffRecord',
      },
    },
  ],
};
