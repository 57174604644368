import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const Company = lazy(() => import('./company'));
const CompanyFlagship = lazy(() => import('./companyFlagship'));
const Form = lazy(() => import('./form'));

export default {
  path: 'dataPermission',
  element: <Outlet />,
  options: {
    title: '数据权限',
    permissionKey: 'menu-jurisdiction_data',
    key: 'menu-jurisdiction_data',
    icon: null,
    link: '/permission/dataPermission',
  },
  children: [
    {
      path: 'company',
      element: <Outlet />,
      options: {
        title: '企业管理',
        permissionKey: 'menu-jurisdiction_data_company',
        key: 'menu-jurisdiction_data_company',
        icon: null,
        link: '/permission/dataPermission/company',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-jurisdiction_data_company" title="企业管理" component={<Suspense><Company /></Suspense>} />,
        },
        {
          path: ':coreCompanyId/edit',
          element: <ProtectedRoute title="修改企业" permissionKey="menu-jurisdiction_data_company" component={<Suspense><Form /></Suspense>} />,
        },
        {
          path: 'create',
          element: <ProtectedRoute title="添加企业" permissionKey="menu-jurisdiction_data_company" component={<Suspense><Form /></Suspense>} />,
        },
      ],
    },
    {
      path: 'flagshipCompany',
      element: <Outlet />,
      options: {
        title: '企业管理',
        permissionKey: 'plus-menu-jurisdiction_data_company',
        key: 'plus-menu-jurisdiction_data_company',
        icon: null,
        link: '/permission/dataPermission/flagshipCompany',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="plus-menu-jurisdiction_data_company" title="企业管理" component={<Suspense><CompanyFlagship /></Suspense>} />,
        },
        {
          path: ':coreCompanyId/edit',
          element: <ProtectedRoute title="修改企业" permissionKey="plus-menu-jurisdiction_data_company" component={<Suspense><Form /></Suspense>} />,
        },
      ],
    },
  ],
};
