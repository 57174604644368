import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { transform } from 'lodash';

const MySuspense = ({ children }) => (
  <Suspense
    fallback={(
      <Spin
        spinning
        tip="页面加载中……"
        style={{
          left: '50%', top: '50%', position: 'absolute', transform: 'transform(-50%, -50%)',
        }}
      />
    )}
  >
    {children}
  </Suspense>
);

MySuspense.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MySuspense;
