import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '@ant-design/icons';
import payroll from '@/modules/payroll';

import {
  IconWeChat,
} from '@/assets/svg';

export default {
  path: 'weChat',
  element: <Outlet />,
  options: {
    title: '微信发薪',
    permissionKey: 'menu-payroll',
    key: 'menu-payroll',
    icon: <Icon component={IconWeChat} />,
    link: '/weChat',
  },
  children: [
    payroll,
  ],
};
