import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const Pay = lazy(() => import('./pay'));
const Detailed = lazy(() => import('./detailed'));
const Status = lazy(() => import('./status'));
export default {
  path: 'payRecord',
  element: <Outlet />,
  options: {
    title: '预支记录',
    permissionKey: 'menu-advance_record',
    key: 'menu-advance_record',
    icon: null,
    link: '/advance/payRecord',
  },
  children: [
    {
      path: 'apply',
      element: <ProtectedRoute permissionKey="menu-advance_record_laster" title="申请记录" component={<Suspense><Status /></Suspense>} />,
      options: {
        title: '申请记录',
        permissionKey: 'menu-advance_record_laster',
        key: 'menu-advance_record_laster',
        icon: null,
        link: '/advance/payRecord/apply',
      },
    },
    {
      path: 'batch',
      element: <Outlet />,
      options: {
        title: '发放记录',
        permissionKey: 'menu-advance_record_batch',
        key: 'menu-advance_record_batch',
        icon: null,
        link: '/advance/payRecord/batch',
      },
      children: [
        {
          path: '',
          element: <ProtectedRoute permissionKey="menu-advance_record_batch" title="批次记录" component={<Suspense><Pay /></Suspense>} />,
        },
        {
          path: 'detailed/:id',
          element: <ProtectedRoute permissionKey="menu-advance_record_batch" title="查看明细" component={<Suspense><Detailed /></Suspense>} />,
        },
      ],
    },
  ],
};
