import styled from 'styled-components';
import { Tabs } from 'antd';

const Content = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
`;
const TabsContent = styled(Tabs)`
  .ant-tabs-nav {
    background-color: #fff;
    padding: 0 12px;
    margin: 0;
  }
`;
export default {
  Content,
  TabsContent,
};
