import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '@ant-design/icons';
import advanceList from '@/modules/advanceList';
import advanceProcess from '@/modules/advanceProcess';
import advanceWriteOff from '@/modules/advanceWriteOff';
import payRecord from '@/modules/payRecord';
import offlineArrears from '@/modules/offlineArrears';
import {
  IconAdvance,
} from '@/assets/svg';

export default {
  path: 'advance',
  element: <Outlet />,
  options: {
    title: '预支',
    permissionKey: 'menu-advance',
    key: 'menu-advance',
    icon: <Icon component={IconAdvance} />,
    link: '/advance',
  },
  children: [
    advanceList,
    advanceProcess,
    advanceWriteOff,
    offlineArrears,
    payRecord,
  ],
};
