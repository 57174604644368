import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';
import {
  IconPayslip,
} from '@/assets/svg';

const Record = lazy(() => import('./review'));
const Distribution = lazy(() => import('./distribution'));
const Detail = lazy(() => import('./detail'));
const WagesDetail = lazy(() => import('./wagesDetail'));

export default {
  path: 'wages',
  element: <Outlet />,
  options: {
    title: '工资条',
    permissionKey: 'menu-wage',
    key: 'menu-wage',
    icon: <Icon component={IconPayslip} />,
    link: '/wages',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute permissionKey="menu-wage" title="工资条" component={<Suspense><Record /></Suspense>} />,
    },
    {
      path: 'distribution',
      element: <ProtectedRoute permissionKey="menu-wage" title="发放工资条" component={<Suspense><Distribution /></Suspense>} />,
    },
    {
      path: ':id/detail',
      element: <ProtectedRoute permissionKey="menu-wage" title="工资条明细" component={<Suspense><Detail /></Suspense>} />,
    },
    {
      path: ':id/wages-detail',
      element: <ProtectedRoute permissionKey="menu-wage" title="工资条明细" component={<Suspense><WagesDetail /></Suspense>} />,
    },
  ],
};
