import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

const Popcon = ({ text, color, width }) => (
  <Popover content={<div style={{ width: '250px', 'word-break': 'break-all' }} dangerouslySetInnerHTML={{ __html: text }} />} trigger="hover">
    <div
      style={{
        width,
        color,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </Popover>
);

Popcon.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number || PropTypes.string,
};

Popcon.defaultProps = {
  text: '',
  color: '',
  width: 190,
};

export default Popcon;
