import React, { useState, useEffect } from 'react';
import {
  Button, Row, Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import { globalStyles } from '@styles';

const { Content } = globalStyles;
const DateSelection = ({
  values, onSubmit, dateArray, colArray, rowArray,
}) => {
  const [optionalDate, setOptionalDate] = useState(dateArray);
  const [checkColList, setCheckColList] = useState(colArray);
  const [checkRowList, setCheckRowList] = useState(rowArray);

  const handleDataClick = (item, isCheck) => {
    setCheckColList((valArray) => {
      const Arr = valArray;
      if (isCheck) {
        Arr[item.col].count -= 1;
      } else {
        Arr[item.col].count += 1;
      }
      return Arr;
    });
    setCheckRowList((valArray) => {
      const Arr = valArray;
      if (isCheck) {
        Arr[item.row].count -= 1;
      } else {
        Arr[item.row].count += 1;
      }
      return Arr;
    });
    setOptionalDate((val) => {
      const currentArray = val.map((o) => {
        if (o.value === item.value) {
          return {
            ...o,
            selected: !isCheck,
          };
        }
        return o;
      });
      const data = currentArray.filter((o) => o.selected).map((i) => i.value);
      onSubmit(data);
      return currentArray;
    });
  };
  const handldCheckboxClick = (e, item) => {
    setOptionalDate((val) => {
      const returnArr = val.map((o) => {
        if ((Number(e.target.value) === o.col && item.type === 'col') || (Number(e.target.value) === o.row && item.type === 'row')) {
          return {
            ...o,
            selected: e.target.checked,
          };
        }
        return o;
      });
      setCheckColList((checkColVal) => checkColVal.map((q, index) => ({
        ...q,
        count: returnArr.filter((i) => index === i.col && i.selected).length,
      })));
      setCheckRowList((checkColVal) => checkColVal.map((q, index) => ({
        ...q,
        count: returnArr.filter((i) => index === i.row && i.selected).length,
      })));
      const data = returnArr.filter((o) => o.selected).map((i) => i.value);
      onSubmit(data);
      return returnArr;
    });
  };

  useEffect(() => {
    if (values.length) {
      setOptionalDate((val) => {
        const returnArr = val.map((o) => {
          if (values?.includes(o.value)) {
            return {
              ...o,
              selected: true,
            };
          }
          return o;
        });
        setCheckColList((checkColVal) => checkColVal.map((q, index) => ({
          ...q,
          count: returnArr.filter((i) => index === i.col && i.selected).length,
        })));
        setCheckRowList((checkColVal) => checkColVal.map((q, index) => ({
          ...q,
          count: returnArr.filter((i) => index === i.row && i.selected).length,
        })));
        return returnArr;
      });
    }
  }, [values]);
  return (
    <Content>
      <div style={{ width: '275px' }}>
        <div style={{
          display: 'flex', width: '245px', marginLeft: '30px',
        }}
        >
          {
            checkColList.map((item, index) => (
              <Checkbox value={index} style={{ width: '35px', marginLeft: '10px' }} onChange={(e) => handldCheckboxClick(e, item)} checked={item.count === item.total} indeterminate={item.count > 0 && item.count < item.total} />
            ))
          }
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-around',
          }}
          >
            {
              checkRowList.map((item, index) => (
                <Checkbox value={index} style={index === 0 ? { marginLeft: '8px' } : null} onChange={(e) => handldCheckboxClick(e, item)} checked={item.count === item.total} indeterminate={item.count > 0 && item.count < item.total} />
              ))
            }
          </div>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '245px',
          }}
          >
            {
              optionalDate.map((item) => (
                <Button size="small" type={item.selected ? 'primary' : 'text'} shape="circle" style={{ marginTop: '10px', marginLeft: '11px' }} onClick={() => handleDataClick(item, item.selected)}>
                  {item.value}
                </Button>
              ))
            }
          </div>
        </div>
      </div>
    </Content>
  );
};
DateSelection.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.arrayOf,
  dateArray: PropTypes.arrayOf(
    {
      row: PropTypes.string,
      col: PropTypes.string,
      value: PropTypes.number,
      selected: PropTypes.bool,
    },
  ),
  colArray: PropTypes.arrayOf({
    type: PropTypes.string,
    count: PropTypes.number,
    total: PropTypes.number,
    label: PropTypes.string,
  }),
  rowArray: PropTypes.arrayOf({
    type: PropTypes.string,
    count: PropTypes.number,
    total: PropTypes.number,
    label: PropTypes.string,
  }),
};
DateSelection.defaultProps = {
  values: [],
  onSubmit: () => {},
  dateArray: [],
  colArray: [],
  rowArray: [],
};

export default DateSelection;
