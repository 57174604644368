export { default as datetimeFormat } from './datetimeFormat';
export { default as storageKeys } from './storageKeys';
export { default as options } from './option';
export { default as areaFormat } from './areaFormat';
export { default as types } from './type';
export { default as regExp } from './regExp';
export { default as address } from './address';
export { default as rangePresets } from './rangePresets';
export { default as moneyArray } from './moneyArray';
export { default as tagsArray } from './tagsArray';
export { default as pageSize } from './pageNumber';
export const amountFormat = '0,0.00';
export const searchFieldCol = {
  basic: {
    xxl: 6, lg: 8, md: 10, sm: 14,
  },
  twoTimes: {
    xxl: 8, lg: 12, md: 16, sm: 24,
  },
};
export default {};
