/* eslint-disable no-param-reassign */
import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {},
});

const handleUnauthorized = () => {
  Cookies.remove('token');
  if (
    process.env.NODE_ENV === 'development'
    && process.env.REACT_APP_LOCAL_LOGIN === 'true') {
    // 该条件仅在本地开发环境生效
    window.location.href = `${window.location.origin}${process.env.REACT_APP_ROUTER_BASENAME ? `/${process.env.REACT_APP_ROUTER_BASENAME}` : ''}/login`;
  } else {
    window.location.href = `${process.env.REACT_APP_PORTAL_ADDRESS}?from=${encodeURIComponent(window.location.href)}`;
  }
};

// Add a request interceptor
instance.interceptors.request.use((config) => {
  const token = Cookies.get('token');
  // const token = 'eyJhbGciOiJIUzI1NiJ9.eyJyYW5kb20iOiI1ZDM1NTUyOC01MjRjLTQ2ZjYtYTMzMC1iNDZmYTVmMjJlMTQiLCJzZXJ2aWNlIjoic2hhbmtzLW1hbmFnZXItYmFja2VuZCx0ZXp6b2xvLWZpbmFuY2UtbWFuYWdlciIsInRlbmFudElkIjoiNjI1NzkwN2E1ZTU1MDAwMGE0MDAzYWY2IiwibW9iaWxlIjoiMTk5MjY0MDM0MzEiLCJ0ZXJtaW5hbCI6InBjIiwidXNlcklkIjoiNjI1ZjZmOTE5MmRmODIwZWQxY2JiNjY5In0.ZAaC5_dDfHPNxnCsskJIIw1A5wxN3ubEb3jKGDg0N9k';
  if (token) {
    config.headers['X-User-Token'] = token;
  }
  return config;
}, (error) => Promise.reject(error));

// Add a response interceptor
instance.interceptors.response.use((response) => {
  const res = response.headers['content-disposition']?.split(';')[0] === 'attachment' ? response : response.data;
  if (res.code === 10) {
    handleUnauthorized();
  }
  return res;
}, (error) => {
  if (error?.response?.status === 401) {
    handleUnauthorized();
  }
  return Promise.reject(error);
});

export default instance;
