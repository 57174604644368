import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const ListManage = lazy(() => import('./listManage/index'));
const ListManageFlagship = lazy(() => import('./listManageFlagship/index'));
const PayAdvance = lazy(() => import('./payAdvance'));

export default {
  path: 'advanceList',
  element: <Outlet />,
  options: {
    title: '预支配置',
    permissionKey: 'menu-advance_config',
    key: 'menu-advance_config',
    icon: null,
    link: '/advance/advanceList',
  },
  children: [
    {
      path: 'manage',
      element: (
        <ProtectedRoute permissionKey="menu-advance_config_staff" title="名单管理" component={<Suspense><ListManage /></Suspense>} />
      ),
      options: {
        title: '名单管理',
        permissionKey: 'menu-advance_config_staff',
        key: 'menu-advance_config_staff',
        icon: null,
        link: '/advance/advanceList/manage',
      },
    },
    {
      path: 'flagshipManage',
      element: (
        <ProtectedRoute permissionKey="plus-menu-advance_config_staff" title="名单管理" component={<Suspense><ListManageFlagship /></Suspense>} />
      ),
      options: {
        title: '名单管理',
        permissionKey: 'plus-menu-advance_config_staff',
        key: 'plus-menu-advance_config_staff',
        icon: null,
        link: '/advance/advanceList/flagshipManage',
      },
    },
    {
      path: 'payAdvance',
      element: <ProtectedRoute permissionKey="menu-advance_config_rule" title="预支规则" component={<Suspense><PayAdvance /></Suspense>} />,
      options: {
        title: '预支规则',
        permissionKey: 'menu-advance_config_rule',
        key: 'menu-advance_config_rule',
        icon: null,
        link: '/advance/advanceList/payAdvance',
      },
    },
  ],
};
