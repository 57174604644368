import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Steps, Row, Col, Drawer,
} from 'antd';
import dayjs from 'dayjs';
import numeral from 'numeral';
import WarnTips from '@/assets/img/warnTips.png';
import SucceesTick from '@/assets/img/succeesTick.png';
import ErrTips from '@/assets/img/errTips.png';
import Withdraw from '@/assets/img/withdraw.png';
import Refuse from '@/assets/img/refuse.png';
import Pay from '@/assets/img/pay.png';
import Deduction from '@/assets/img/deduction.png';
import Examine from '@/assets/img/examine.png';
import ChangeTick from '@/assets/img/changeTick.png';
import {
  datetimeFormat, amountFormat, moneyArray, regExp,
} from '@/constants';

const iconType = {
  LAUNCH: <img src={WarnTips} alt="WarnTips" />,
  AGREE: <img src={SucceesTick} alt="SucceesTick" />,
  REFUSE: <img src={ErrTips} alt="ErrTips" />,
  BACK: <img src={WarnTips} alt="WarnTips" />,
  DEDUCTED: <img src={ErrTips} alt="ErrTips" />,
  WITHDRAW: <img src={SucceesTick} alt="SucceesTick" />,
  APPROVAL_AMT: <img width={36} src={ChangeTick} alt="ChangeTick" />,
};
const imageType = {
  APPLY_RUNNING: <img src={Examine} alt="Examine" />,
  APPLY_REFUSE: <img src={Refuse} alt="Refuse" />,
  APPLY_APPROVE: <img src={Pay} alt="Pay" />,
  APPLY_DEDUCTED: <img src={Deduction} alt="Deduction" />,
  APPLY_WITHDRAW: <img src={Withdraw} alt="Withdraw" />,
};
const Process = ({
  open, onCloseModal, data,
}) => {
  const itemArray = (() => data?.processDetailList?.map((item) => ({
    title: (
      <>
        {item?.name}
        （
        {item?.resultDesc}
        ）
      </>
    ),
    icon: iconType[item.result],
    description: (
      <Row>
        <Col span={24}>
          处理节点：
          {item.nodeDesc || '--'}
        </Col>
        <Col span={24}>
          处理时间：
          {item.time ? dayjs(item.time).format(datetimeFormat.dateTime) : '--'}
        </Col>
        {
          item.approvalAmt > 0 && (
          <Col span={24}>
            审批金额：
            {numeral(item.approvalAmt).format(amountFormat) || '--'}
            元
          </Col>
          )
        }
        {
          item?.approvalHours > 0 && (
            <Col span={24}>
              审批工时：
              {item.approvalHours}
              时
            </Col>
          )
        }
        {
          item?.remark && (
          <Col span={24}>
            审批备注：
            {item.remark}
          </Col>
          )
        }
      </Row>
    ),
  })))() || [];
  return (
    <Drawer title="审批流程" open={open} onClose={() => onCloseModal()} size="large">
      <Row style={{ lineHeight: '40px', fontSize: '20px', fontWeight: 600 }}>
        {data?.name}
        -提交的申请
      </Row>
      <Row
        style={{
          padding: '10px', borderRadius: '5px', marginBottom: '10px', backgroundColor: '#f1f1f1',
        }}
      >
        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span="24">
              身份证号：
              {data?.idNo || '--'}
            </Col>
            <Col span="24">
              所属企业：
              {data?.shortCompanyName || '--'}
            </Col>
            <Col span="24">
              员工工号：
              {data?.jobNo || '--'}
            </Col>
            <Col span="24">
              申请备注：
              {data?.applyRemark || '--'}
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          {imageType[data?.applyStatus]}
        </Col>
      </Row>
      <Steps
        direction="vertical"
        current={100}
        items={[
          {
            title: `${data?.name}（发起申请）`,
            icon: iconType.AGREE,
            description: (
              <Row>
                <Col span={24}>
                  处理节点：员工
                </Col>
                <Col span={24}>
                  处理时间：
                  {data.applyTime ? dayjs(data.applyTime).format(datetimeFormat.dateTime) : '--'}
                </Col>
                <Col span={24}>
                  申请金额：
                  {numeral(data.applyAmt).format(amountFormat) || '--'}
                  元
                </Col>
              </Row>
            ),
          },
          ...itemArray,
        ]}
      />
    </Drawer>
  );
};

Process.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
  data: PropTypes.objectOf({}),
};
Process.defaultProps = {
  open: false,
  onCloseModal: () => {},
  data: {},
};

export default Process;
