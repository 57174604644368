export default {
  blacklistTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E9%BB%91%E5%90%8D%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  rosterTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E8%8A%B1%E5%90%8D%E5%86%8C%E6%9B%B4%E6%96%B0_%E6%A8%A1%E6%9D%BF.xlsx',
  recruitTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E8%87%AA%E6%8B%9B%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E7%89%88.xlsx',
  supplierTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E4%BE%9B%E5%BA%94%E5%95%86%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  onJobTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%9C%A8%E8%81%8C%E5%90%8D%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF_.xlsx',
  departureTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E7%A6%BB%E8%81%8C%E6%9B%B4%E6%96%B0_%E6%A8%A1%E6%9D%BF.xlsx',
  supplierManagementTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E4%BE%9B%E5%BA%94%E5%95%86%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  nameList: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E9%A2%84%E6%94%AF%E4%BC%9A%E5%91%98%E5%90%8D%E5%8D%95_%E6%A8%A1%E6%9D%BF.xlsx',
  onTheJob: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8F%91%E8%96%AA%E7%B3%BB%E7%BB%9F-%E5%9C%A8%E8%81%8C%E5%90%8D%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  leaveJob: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8F%91%E8%96%AA%E7%B3%BB%E7%BB%9F-%E7%A6%BB%E8%81%8C%E6%9B%B4%E6%96%B0_%E6%A8%A1%E6%9D%BF.xlsx',
  advance: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8F%91%E8%96%AA%E7%B3%BB%E7%BB%9F-%E5%BC%80%E5%85%B3%E9%A2%84%E6%94%AF%E5%8A%9F%E8%83%BD_%E6%A8%A1%E6%9D%BF.xlsx',
  dataImport: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%B7%A5%E6%97%B6%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  arrearsTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8F%91%E8%96%AA%E7%B3%BB%E7%BB%9F-%E5%AF%BC%E5%85%A5%E6%AC%A0%E6%AC%BE_%E6%A8%A1%E6%9D%BF.xlsx',
  jobNumber: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8F%91%E8%96%AA%E7%B3%BB%E7%BB%9F-%E5%AF%BC%E5%85%A5%E5%B7%A5%E5%8F%B7_%E6%A8%A1%E6%9D%BF.xlsx',
  refuseTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E6%89%B9%E9%87%8F%E6%8B%92%E7%BB%9D_%E6%A8%A1%E6%9D%BF.xlsx',
};
