import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const User = lazy(() => import('./user'));
const Role = lazy(() => import('./role'));
const RoleDetail = lazy(() => import('./role/addRole'));
const ManageAccount = lazy(() => import('./role/manageAccount'));

export default {
  path: 'setting',
  element: <Outlet />,
  options: {
    title: '用户权限',
    permissionKey: 'menu-jurisdiction_user',
    key: 'menu-jurisdiction_user',
    icon: null,
    link: '/permission/setting',
  },
  children: [
    {
      path: 'user',
      element: <ProtectedRoute permissionKey="menu-jurisdiction_user_userManage" title="用户管理" component={<Suspense><User /></Suspense>} />,
      options: {
        title: '用户管理',
        permissionKey: 'menu-jurisdiction_user_userManage',
        key: 'menu-jurisdiction_user_userManage',
        icon: null,
        link: '/permission/setting/user',
      },
    },
    {
      path: 'role',
      element: <Outlet />,
      options: {
        title: '角色管理',
        key: 'menu-jurisdiction_user_role',
        permissionKey: 'menu-jurisdiction_user_role',
        icon: null,
        link: '/permission/setting/role',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-jurisdiction_user_role" title="角色管理" component={<Suspense><Role /></Suspense>} />,
        },
        {
          path: 'addRole',
          element: <ProtectedRoute permissionKey="menu-jurisdiction_user_role" title="新增角色管理" component={<Suspense><RoleDetail /></Suspense>} />,
          options: {
            title: '新增角色管理',
            key: 'menu-jurisdiction_user_role',
            permissionKey: 'menu-jurisdiction_user_role',
            icon: null,
            link: '/permission/setting/role/addRole',
            displayInMenu: false,
          },
        },
        {
          path: ':roleId/addRole',
          element: <ProtectedRoute permissionKey="menu-jurisdiction_user_role" title="编辑角色管理" component={<Suspense><RoleDetail /></Suspense>} />,
          options: {
            title: '编辑角色管理',
            key: 'menu-jurisdiction_user_role',
            permissionKey: 'menu-jurisdiction_user_role',
            icon: null,
            link: '/permission/setting/role/:roleId/addRole',
            displayInMenu: false,
          },
        },
        {
          path: 'manageAccount',
          element: <ProtectedRoute permissionKey="menu-jurisdiction_user_role" title="管理帐号" component={<Suspense><ManageAccount /></Suspense>} />,
          options: {
            title: '管理帐号',
            key: 'menu-jurisdiction_user_role',
            permissionKey: 'menu-jurisdiction_user_role',
            icon: null,
            link: '/permission/setting/role/manageAccount',
            displayInMenu: false,
          },
        },
      ],
    },
  ],
};
