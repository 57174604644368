import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const Debt = lazy(() => import('./debt'));

export default {
  path: 'debt',
  element: <Outlet />,
  options: {
    title: '线下欠款',
    permissionKey: 'menu-advance_debt',
    key: 'menu-advance_debt',
    icon: null,
    link: '/advance/debt',
  },
  children: [
    {
      path: 'debt',
      element: <ProtectedRoute permissionKey="menu-advance_debt_manage" title="欠款管理" component={<Suspense><Debt /></Suspense>} />,
      options: {
        title: '欠款管理',
        permissionKey: 'menu-advance_debt_manage',
        key: 'menu-advance_debt_manage',
        icon: null,
        link: '/advance/debt/debt',
      },
    },
  ],
};
