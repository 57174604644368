import React, { lazy } from 'react';
import dashboard from '@modules/dashboard';
import NotAuthorized from '@modules/error/notAuthorized';
import account from '@/modules/account';
import wages from '@/modules/wages';
import member from '@/modules/member';
import weChat from './weChat';
import advance from './advance';
import permission from './permission';
import settlement from '@/modules/settlement';

export default [
  dashboard,
  account,
  settlement,
  weChat,
  advance,
  member,
  wages,
  permission,
  {
    path: 'not-authorized',
    element: <NotAuthorized />,
  },
];
