import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import QRCode from 'qrcode.react';
import {
  Avatar, Space, Dropdown, Menu, message, Button, Divider, Tag, Image, Modal,
} from 'antd';
import copy from 'copy-to-clipboard';
import { UserOutlined, LogoutOutlined, QrcodeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import BasePng from '@assets/img/base.png';
import ProfessionalPng from '@assets/img/professional.png';
import { httpRequest } from '@/utils';
import { globalStyles } from '@/styles';

import { HeaderContainer, LogoutButton } from './styles';

const { Content } = globalStyles;
const Header = ({
  tenantId, tenantName, username, avatar, onLogout,
}) => {
  const imgUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx27e76ff8d52078ef&redirect_uri=${process.env.REACT_APP_WECHAT_URL}&response_type=code&scope=snsapi_base&state=${tenantId}#wechat_redirect`;
  const [data, setData] = useState({});
  const [showObj, setShowObj] = useState({});
  const [open, setOpen] = useState(false);
  const getStatictics = async (o) => {
    try {
      const res = await httpRequest('/system/service/expired');
      if (res.code !== 0) {
        throw new Error(res.msg);
      }
      setData(res.data);
    } catch (err) {
      message.error(err.message || '请求失败');
    }
  };

  const handleOk = () => {
    setOpen(false);
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const formattedDate = `${year}年${month}月${day}日`;
      return formattedDate;
    }
    // 如果没有值就返回空字串
    return '';
  };

  // 弹窗是否展示
  const getPopupShow = async () => {
    try {
      const res = await httpRequest.post('/system/service/expired/notify/inquiry');
      if (res.code !== 0) {
        throw new Error(res.msg);
      }
      setShowObj(res.data);
      setOpen(res.data.show);
    } catch (err) {
      message.error(err.message || '请求失败');
    }
  };

  useEffect(() => {
    getStatictics();
    getPopupShow();
  }, []);
  return (
    <HeaderContainer className="header">
      <Space size="large">
        <div>{tenantName}</div>
        <Dropdown
          placement="bottomRight"
          overlay={(
            <Content style={{ boxShadow: '0px 0px 10px #D3D3D3' }}>
              <div
                style={{
                  marginBottom: '10px',
                  textAlign: 'center',
                }}
                aria-hidden="true"
              />
              <div>
                <div>
                  付费状态：
                  <Tag color={data?.systemExpired ? 'red' : 'green'}>{data?.systemExpired ? '已过期' : '正常'}</Tag>
                </div>
                <Divider
                  dashed
                  style={{
                    marginTop: '6px',
                    marginBottom: '6px',
                  }}
                />
                <div>
                  服务到期日：
                  {formatTimestamp(data.systemExpireDate)}
                </div>
              </div>
            </Content>
          )}
        >
          <Space size={2}>
            <img
              style={{
                width: '72px',
                height: '28px',
              }}
              alt=""
              src={data?.systemExpired ? BasePng : ProfessionalPng}
            />
          </Space>
        </Dropdown>

        <Dropdown overlay={(
          <Content style={{ boxShadow: '0px 0px 10px #D3D3D3' }}>
            <div
              style={{
                marginBottom: '10px', textAlign: 'center',
              }}
              aria-hidden="true"
              onClick={() => {
                if (copy(imgUrl)) {
                  message.success('复制成功');
                } else {
                  message.error('复制失败');
                }
              }}
            >
              <Button type="primary" ghost>
                会员端公众号链接获取
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <QRCode className="QRCode-qrCode" value={imgUrl} size={170} />
            </div>
          </Content>
        )}
        >
          <Space size={2}>
            <QrcodeOutlined style={{ color: '#217EFF' }} />
            <span>产品端</span>
          </Space>
        </Dropdown>

        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item key="logout" onClick={onLogout}>
                退出登录
              </Menu.Item>
            </Menu>
          )}
        >
          <Space>
            <span className="username">{username}</span>
            {avatar ? <Avatar size={30} src={avatar} /> : <Avatar size={30} icon={<UserOutlined />} />}
          </Space>
        </Dropdown>
      </Space>
      <Modal
        title={[
          <h2 style={{
            textAlign: 'center',
            fontWeight: 'bold',
          }}
          >
            系统服务到期通知
          </h2>,
        ]}
        open={open}
        onOk={handleOk}
        centered
        maskClosable={false}
        onCancel={handleOk}
        width={700}
        footer={[
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              key="submit"
              style={{
                width: '200px',
                marginTop: '20px',
              }}
              type="primary"
              onClick={handleOk}
            >
              我知道了
            </Button>
          </div>,
        ]}
      >
        <div style={{ height: '200px', fontSize: '16px' }}>
          <div style={{ padding: '8px 0' }}>尊敬的用户：</div>
          <div style={{ padding: '8px 0' }}>
            您的系统服务到期日为
            <span style={{ color: 'red' }}>{formatTimestamp(showObj.systemExpireDate)}</span>
          </div>
          <div style={{ padding: '8px 0' }}>为确保您持续畅享我们的服务，请尽早联系云彩人资科研的支持团队完成系统续费。</div>
          <div style={{ padding: '8px 0' }}>如果您有任何问题或需要帮助，我们将随时为您提供支持。期待继续为您提供卓越的服务！</div>
        </div>

      </Modal>
    </HeaderContainer>
  );
};

Header.propTypes = {
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  tenantId: PropTypes.string,
  tenantName: PropTypes.string,
  onLogout: PropTypes.func,
};

Header.defaultProps = {
  avatar: '',
  tenantId: '',
  tenantName: '',
  onLogout: () => {},
};
export default Header;
