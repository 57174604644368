import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '@ant-design/icons';
import setting from '@/modules/setting';
import dataPermission from '@/modules/dataPermission';

import {
  IconSet,
} from '@/assets/svg';

export default {
  path: 'permission',
  element: <Outlet />,
  options: {
    title: '权限',
    permissionKey: 'menu-jurisdiction',
    key: 'menu-jurisdiction',
    icon: <Icon component={IconSet} />,
    link: '/permission',
  },
  children: [
    setting,
    dataPermission,
  ],
};
